import React from "react";
import AccordionContainer from "components/shared/AccordionContainer";

class AccordionContent extends React.Component {
  render() {
    const { title, title_color, body, video_url } = this.props;

    const video = {
      marginTop: 20, 
      marginBottom: 20
    };

    return (
      <AccordionContainer color={title_color} title={title}>
        <div className="accordion trix-content" dangerouslySetInnerHTML={{ __html: body }} />

        { video_url ? 
        <div className="text-center">
          <video controls="controls" style={video} autoPlay={true} muted={true} loop={true}>
            <source src={video_url} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div> : null }

      </AccordionContainer>
    );
  }
}

export default AccordionContent;
