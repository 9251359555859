import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";

const AccordionContainer = (props) => {
  const [displayAccordion, setDisplayAccordion] = useState(false);
  const { icon, title, children } = props;

  const handleAccordion = () => {
    setDisplayAccordion(!displayAccordion);
  };

  const accTitle = {
    padding: "1em",
    textAlign: "left",
    transition: "0.15s",
    backgroundColor: "whitesmoke",
    borderRadius: "5px",
    color: "black",
    cursor: "pointer",
  };

  const accContent = {
    color: "black",
    padding: "1em",
    borderLeft: "1px dotted gray",
    marginLeft: "1em"
  };

  return (
    <div className="f-col-s12 padless m-bottom-2 element-accordion">
      <div className="drop-shadow" style={accTitle} onClick={() => handleAccordion()}>
        <h3 className="marginless">
          {displayAccordion ? <FaMinus /> : <FaPlus />} {icon} {title}
        </h3>
      </div>
      { displayAccordion ? <div style={accContent}>{children}</div> : null }
      <div className="clearfix"/>
    </div>
  );
};

export default AccordionContainer;
