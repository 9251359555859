import React from "react";
import SimpleContainer from "components/shared/SimpleContainer";

class EmbeddableContent extends React.Component {
  // htmlDecode(input){
  //   var e = document.createElement('div');
  //   e.innerHTML = input;
  //   return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  // }
  render() {
    const { title, title_color, body, icon } = this.props;
    return (
      <SimpleContainer color={title_color} title={title} icon={icon}>
        <div dangerouslySetInnerHTML={{ __html: body.replace(/\$amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&quot;/g, ">").replace(/&#039;/g, "'") }} />
      </SimpleContainer>
    );
  }
}

export default EmbeddableContent;
