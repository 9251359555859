import React from 'react';
import { default as App } from '@carnegie-mellon-robotics-academy/cs2n-blockly';

const CS2NBlockly = () => {
  return (
    <div>
      <App />
    </div>
  );
};

export default CS2NBlockly;
