import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import Tooltip from "../shared/StoreTooltip";

const CustomerCheckout = (props) => {
  const cs2n_url =
    process.env.NODE_ENV == "development"
      ? "http://localhost:5000/"
      : "https://www.cs2n.org/";
  const [requirements, setRequirements] = useState(null);
  const [cs2n_user, setCs2nUser] = useState(props.username || "");
  const [poc_email, setPocEmail] = useState(props.email || null);
  const [teacherInfo, setTeacherInfo] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [cs2nError, setCs2nError] = useState(null);
  const url_base_prod = "https://commerce.cashnet.com/CMU245";
  const url_base_local = "https://train.cashnet.com/CMU245TEST";
  let url_base = "";
  useEffect(() => {
    if (props.cart) {
      set_requirements();
    }
  }, [props.cart]);

  const set_requirements = () => {
    let requires = new Array();
    if (requirements) {
      requires = requirements;
    }

    for (let i = 0; i < props.cart.length; i++) {
      const product = props.cart[i].product;
      if (product.product_requirements) {
        for (let j = 0; j < product.product_requirements.length; j++) {
          const requirement = product.product_requirements[j];
          const needsUpdate = true;
          for (let k = 0; k < requires.length; k++) {
            const req = requires[k];
            if (req.name == requirement.name) {
              req.products.push(props.cart[i]);
              needsUpdate = false;
            }
          }
          if (needsUpdate) {
            requires.push({
              name: requirement.name,
              products: [props.cart[i]],
            });
          }
        }
      }
    }

    setRequirements(requires);
  };

  const get_option_type_name = (product) => {
    var option_type = { name: "cs2n", products: [] };
    for (let i = 0; i < product.product_requirements.length; i++) {
      const option = product.product_requirements[i];
      option_type.name = option.name;
      option_type.products.push(product);
    }
    return option_type;
  };
  const validate_user = async (id) => {
    if (id == null || id == "") {
      return "iou";
    }
    const options = {
      method: "GET",
      url: `${cs2n_url}/client_api/v1/get_cs2n_user_validity/${bytesToBase64(
        new TextEncoder().encode(id)
      )}`,
      headers: { Accept: "application/vnd.api+json" },
    };
    try {
      const { data } = await axios.request(options);
      if (data) {
        return data;
      } else {
        console.error("TODO: THROW ERROR FOR CS2N VALIDITY");
        return false;
      }
    } catch (error) {
      console.error(error);
      console.error("TODO: THROW ERROR FOR CS2N VALIDITY");
      return null;
    }
  };
  const bytesToBase64 = (bytes) => {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
  };

  const validate_email = (email) => {
    if (email == "") {
      return false;
    }
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const requirementsInclude = (name) => {
    for (let i = 0; i < requirements.length; i++) {
      const requirement = requirements[i];
      if (name == requirement.name) {
        return requirement;
      }
    }
    return null;
  };
  const handlePurchaseID = useCallback((id) => {
    setCs2nUser(id);
  });
  const handlePocUpdate = useCallback((email) => {
    setPocEmail(email);
  });
  const handleTeacherUpdate = (sku, price, index, data) => {
    var replaceIndex = -1;
    //g(teacherInfo);
    for (let i = 0; i < teacherInfo.length; i++) {
      const info = teacherInfo[i];
      if (info.index == index && info.sku == sku) {
        replaceIndex = i;
      }
    }
    if (replaceIndex >= 0) {
      teacherInfo[replaceIndex] = {
        sku: sku,
        price: price,
        index: index,
        data: data,
      };
    } else {
      teacherInfo.push({
        sku: sku,
        price: price,
        index: index,
        data: data,
      });
    }
    setTeacherInfo(teacherInfo);
  };
  const handleSubmit = (e) => {
    submit();
    e.preventDefault();
  };
  const submit = async () => {
    setCs2nError(null);
    setEmailError(null);

    const params = new Map();
    params.set("245-CS2NTOKEN", "50e64be9514f7d8fc65f517f0bbd9d8c");

    /*
    {
      "245-CS2NTOKEN": "50e64be9514f7d8fc65f517f0bbd9d8c",
      client: "CMU_TRAIN",
      SID: "245",
      commit: "Checkout",
    };
    */
    window.scrollTo(0, 0);
    if (poc_email) {
      if (validate_email(poc_email)) {
      } else {
        setEmailError("A valid email address is required.");
        return;
      }
    } else {
      setEmailError("Please provide a valid email.");
      return;
    }
    if (cs2n_user) {
      const validCs2n = await validate_user(cs2n_user);

      if (!validCs2n) {
        setCs2nError(
          "Username or Email does not exist on CS2N.org. Leave blank if you are unsure."
        );
        return;
      }
    }

    if (process.env.NODE_ENV === "development") {
      url_base = url_base_local;
    } else {
      url_base = url_base_prod;
    }

    params.set("245-EMAIL", poc_email);

    if (cs2n_user != "") {
      params.set("custcode", cs2n_user);
    } else {
      params.set("custcode", "iou");
    }
    let currentItemIndex = 0;
    let currentItemCode = 0;

    for (let t = 0; t < teacherInfo.length; t++) {
      const teacher = teacherInfo[t];
      if (teacher.data.name == "") {
        teacher.data.name = "iou";
      }
      if (teacher.data.email == "") {
        teacher.data.email = "iou";
      }
      if (teacher.data.org == "") {
        teacher.data.org = "iou";
      }
      params.set(`itemcode${t + 1}`, teacher.sku);
      params.set(`qty${t + 1}`, 1);
      params.set(
        `amount${t + 1}`,
        (Math.round(teacher.price * 100) / 100).toFixed(2)
      );

      params.set(`245-0001-${currentItemCode}`, teacher.data.name);
      currentItemCode++;
      params.set(`245-0002-${currentItemCode}`, teacher.data.email);
      currentItemCode++;
      params.set(`245-0003-${currentItemCode}`, teacher.data.org);
      currentItemCode++;

      currentItemIndex++;
    }

    for (let p = 0; p < props.cart.length; p++) {
      const product = props.cart[p].product;
      if (get_option_type_name(product).name != "trainee") {
        params.set(`itemcode${currentItemIndex + p + 1}`, product.sku);
        params.set(`qty${currentItemIndex + p + 1}`, props.cart[p].quantity);
        params.set(
          `amount${currentItemIndex + p + 1}`,
          (
            Math.round(
              parseFloat(product.price) * parseInt(props.cart[p].quantity) * 100
            ) / 100
          ).toFixed(2)
        );
      }
    }
    postForm(url_base, params);
    props.submit();
  };
  const postForm = (path, params, method = "post") => {
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (let [key, value] of params) {
      var newKey = key;
      if (key.split("-").length >= 3) {
        var splitKey = key.split("-");

        newKey = splitKey[0] + "-" + splitKey[1];
      }
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = newKey;
      hiddenField.value = value;
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    //  console.log(form);
    form.submit();
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="user-checkout-info">
        <PointOfContact
          email={props.email}
          handlePocUpdate={handlePocUpdate}
          error={emailError}
        />
        {requirements && requirementsInclude("cs2n") && (
          <Cs2nRequirement
            username={props.username}
            handlePurchaseID={handlePurchaseID}
            error={cs2nError}
          />
        )}
      </div>
      {requirements && requirementsInclude("trainee") && (
        <div>
          {requirementsInclude("trainee").products.map((item, index) => (
            <div key={index + "product"} className="participant-info">
              <div
                key={"participant_title" + item.product.name}
                className="participant_product_title"
              >
                <img
                  className="cart-product-image"
                  key={"participant_" + item.product.name}
                  src={item.product.images[0].thumbnail}
                  style={{ width: "100px", height: "100px" }}
                />
                {item.product.name}
              </div>
              <div
                key={"teacherInfo" + item.product.name}
                className="teacher_info_list"
              >
                {Array(parseInt(item.quantity))
                  .fill()
                  .map((_key, index) => {
                    return (
                      <TeacherInfo
                        key={index + "qty"}
                        sku={item.product.sku}
                        price={item.product.price}
                        image={item.product.images[0].thumbnail}
                        index={index}
                        handleTeacherUpdate={handleTeacherUpdate}
                      />
                    );
                  })}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="cashnet-button">
        <input
          type="submit"
          value="Continue to Secure Payment"
          className="checkout-button"
        />
        <span className="cashnet-text">
          * You will be directed to the Cashnet secure encrypted payment system
          used by Carnegie Mellon University.
        </span>
      </div>
    </form>
  );
};

const Cs2nRequirement = (props) => {
  const [username, setUsername] = useState(props.username || "");
  const handleChange = (e) => {
    setUsername(e.target.value);
    props.handlePurchaseID(e.target.value);
  };

  return (
    <div className="customer-checkout-panel">
      <div className="customer-info">
        <div className="user-purchase-input">
          <div className="customer-input">
            <label
              className={props.error ? "error-input-label" : "input-label"}
            >
              Apply purchase to (recommended):
            </label>
            <input
              className={
                props.error || props.username == null
                  ? "input-field-error"
                  : "input-field"
              }
              type="text"
              value={username}
              placeholder="CS2N Username/Email"
              onChange={handleChange}
            />
            <Tooltip
              text={
                "Please provide a valid CS2N Username or Email. Any Virtual Licenses, Certification seat, or CS2N Premium will be deposited to this account. If you do not provide one, we will contact your Billing Email for more information."
              }
            >
              <i
                onTouchStart={() => {}}
                className="question_icon fas fa-question-circle"
              />
            </Tooltip>
          </div>
          {props.error != null && (
            <span className="error-input-message">
              <i className="warning-icon fas fa-exclamation-triangle fa-lg"></i>
              {props.error}
            </span>
          )}
        </div>
        <div className="option-row">
          {props.username == null && (
            <div className="cs2n-buttons">
              <div
                className="sign_in-button"
                onClick={() => {
                  window.open("https://www.cs2n.org/sign_in");
                }}
              >
                Sign In
              </div>
              <div
                className="sign_up-button"
                onClick={() => {
                  window.open("https://www.cs2n.org/sign_up");
                }}
              >
                Sign Up
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PointOfContact = (props) => {
  const [poc, setPoc] = useState(props.email || "");

  const handleChange = (e) => {
    setPoc(e.target.value);
    props.handlePocUpdate(e.target.value);
  };

  return (
    <div className="customer-info">
      <div className="customer-input">
        <label className={props.error ? "error-input-label" : "input-label"}>
          Billing Email:
        </label>
        <input
          className={
            props.error || props.email == null
              ? "input-field-error"
              : "input-field"
          }
          type="text"
          placeholder="Email"
          value={poc}
          onChange={handleChange}
        />
        <Tooltip
          text={
            "Please provide a billing email. We will use this to contact you about your purchase. This is a required field."
          }
        >
          <i className="question_icon fas fa-question-circle" />
        </Tooltip>
      </div>
      {props.error && (
        <span className="error-input-message">
          <i className="warning-icon fas fa-exclamation-triangle fa-lg"> </i>
          {props.error}
        </span>
      )}
    </div>
  );
};
const TeacherInfo = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleOrgChange = (e) => {
    setOrg(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  useEffect(() => {
    submit_update();
  }, [name, email, org]);

  const submit_update = () => {
    props.handleTeacherUpdate(props.sku, props.price, props.index, {
      name: name,
      email: email,
      org: org,
    });
  };

  return (
    <div className="participant-inputs">
      <label className="participant-label">Participant {props.index + 1}</label>
      <label>
        Name:
        <input
          className="participant-input"
          type="text"
          value={name}
          onChange={handleNameChange}
        />
      </label>
      <label>
        Email:
        <input
          className="participant-input"
          type="text"
          value={email}
          onChange={handleEmailChange}
        />
      </label>
      <label>
        Organization:
        <input
          className="participant-input"
          type="text"
          value={org}
          onChange={handleOrgChange}
        />
      </label>
    </div>
  );
};

export { CustomerCheckout };
