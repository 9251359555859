import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import QRCode from "qrcode.react";
import ElementContainer from "components/shared/ElementContainer";
import TeacherComment from "components/shared/TeacherComment";
import Modal from "components/shared/Modal";

const MixedMediaArtifact = (props) => {
  const {
    title,
    titleColor,
    prompt,
    url,
    validFormats,
    token,
    requirement,
    requirable,
    reviewable,
    comment,
  } = props;

  const [mixedMedia, setMixedMedia] = useState(props.media);
  const [completed, setCompleted] = useState(props.completed);
  const [status, setStatus] = useState(props.status);
  const [upload, setUpload] = useState(null);
  const [error, setError] = useState(null);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [description, setDescription] = useState("");
  const [display, setDisplay] = useState(false);
  const [previewMedia, setPreviewMedia] = useState(null);

  const ErrorMessage = (props) => {
    const { error } = props;
    if (error) {
      return (
        <div className="text-center danger radius">
          <p>
            {error}
            <br />
            Please try again..
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  const cs2nEndpoint = axios.create({
    headers: {
      authorization: `bearer ${token}`,
      "content-type": "multipart/form-data",
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setWaitingForResponse(true);

    let data = new FormData();
    data.set("mixed_media[upload]", upload);
    data.set("mixed_media[description]", description);
    data.set("requirement_id", requirement.id);

    cs2nEndpoint
      .post("/client_api/v1/mixed_medias", data)
      .then((r) => {
        const responseData = {
          ...r.data.artifact,
          description: r.data.description,
          upload_filename: upload.name,
          upload_url: r.data.artifact.direct_url,
          upload_content_type: upload.type,
        };
        //console.log("upload is: ", upload);
        //console.log("r.data.artifact is: ", r.data.artifact);
        setMixedMedia(responseData);
        setCompleted(r.data.completed);
        setStatus(r.data.status);
        setDisplay(false);
        setError(null);
        setWaitingForResponse(false);
      })
      .catch((e) => {
        setError(e.data);
        setWaitingForResponse(false);
      });
  };

  const ModalHeader = (props) => {
    const { title } = props;

    const span = {
      position: "absolute",
      top: 15,
      right: 15,
    };

    const btn = {
      cursor: "pointer",
      color: "lightgray",
    };

    return (
      <div className="modal-header">
        <h2 className="text-center">{title}</h2>
        <span style={span}>
          <a onClick={() => setDisplay(false)} style={btn}>
            <FaTimes className="fa-2x" />
          </a>
        </span>
      </div>
    );
  };

  return (
    <ElementContainer
      color={titleColor}
      title={title}
      requirable={requirable}
      reviewable={reviewable}
      status={status}
      completed={completed}
      icon={"fa-file-upload fa-lg"}
    >
      <div className="m-bottom-1">{prompt}</div>

      {mixedMedia && !error ? (
        <div>
          <ArtifactUpload
            mixedMedia={mixedMedia}
            waitingForResponse={waitingForResponse}
            color={titleColor}
            reviewable={reviewable}
            status={status}
            comment={comment}
          />
          <a
            className="btn btn-secondary radius expand marginless"
            onClick={() => setDisplay(true)}
          >
            Click to Re-upload File
          </a>
        </div>
      ) : (
        <div>
          <a
            className="btn btn-secondary radius expand marginless drop-shadow"
            onClick={() => setDisplay(true)}
          >
            <NoUpload waitingForResponse={waitingForResponse} />
          </a>
        </div>
      )}

      <Modal display={display} modalId={requirement.id}>
        <ModalHeader title={title} />
        <div className="modal-body">
          <form>
            <div className="flex f-d-column f-justify-center">
              <QRCode value={url} className="qr-code" />
            </div>

            <h3 className="text-center primary-text">
              Scan QR code with phone OR select file from computer
              <br />
              Acceptable Types: {validFormats}
            </h3>

            <label className="text-bold">
              Select File (must be less than 500MB)
            </label>
            <input
              className="expand"
              type="file"
              onChange={(e) => setUpload(e.target.files[0])}
            />
            <ErrorMessage error={error} />
            <label className="text-bold">File Description</label>
            <textarea
              className="expand"
              onChange={(e) => setDescription(e.target.value)}
            />
            <a
              onClick={handleSubmit}
              className={
                waitingForResponse
                  ? "btn btn-secondary radius expand"
                  : "btn radius expand"
              }
            >
              {waitingForResponse ? "Uploading. Please wait.." : "Upload File"}
            </a>
          </form>
        </div>
      </Modal>
    </ElementContainer>
  );
};

const ArtifactUpload = (props) => {
  const color = props.color;
  const mixedMedia = props.mixedMedia;
  const status = props.status;
  const reviewable = props.reviewable;
  const comment = props.comment;
  const waitingForResponse = props.waitingForResponse;

  const uploadUrl = mixedMedia?.upload_url;
  const filename = mixedMedia?.upload_filename;
  const content_type = mixedMedia?.upload_content_type;

  var finishedUpload = (
    <div className="f-col-s12 padless">
      <div className="f-col-s12-m6 text-center">
        {content_type.includes("image") ? (
          <a src={uploadUrl} href={uploadUrl}>
            <img className="full-width" src={uploadUrl} alt={filename} />
          </a>
        ) : content_type.includes("video") ? (
          <a src={uploadUrl} href={uploadUrl}>
            <i class="fa fa-video-camera fa-5x" aria-hidden="true"></i>
            <br />
            [Click to download]
          </a>
        ) : (
          <a src={uploadUrl} href={uploadUrl}>
            <i class="fa fa-file fa-5x" aria-hidden="true"></i>
            <br />
            [Click to download]
          </a>
        )}
      </div>

      <div className="f-col-s12-m6 flex f-d-column">
        <label className="text-bold">File Name:</label>
        {filename}

        <label className="text-bold">Description:</label>
        {mixedMedia.description == ""
          ? "[Not Provided]"
          : mixedMedia.description}

        {reviewable && comment ? (
          <TeacherComment comment={comment} status={status} />
        ) : null}
      </div>
    </div>
  );

  var reUpload = (
    <div className="col-row flex f-d-row f-justify-center">
      <h3 className="primary-text">Waiting for upload to complete...</h3>
    </div>
  );

  return waitingForResponse ? reUpload : finishedUpload;
};

const NoUpload = (props) => {
  const waitingForResponse = props.waitingForResponse;
  return (
    <div>
      {waitingForResponse ? (
        <span>{"Waiting for upload to complete..."}</span>
      ) : (
        <span>{"Click to Upload File "}</span>
      )}
    </div>
  );
};

export default MixedMediaArtifact;
