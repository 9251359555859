import React from "react";

const Tooltip = (props) => {
  const { text, children } = props;
  return (
    <div className="tooltip-store">
      {children}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

export default Tooltip;
