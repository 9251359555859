import React from 'react';
import PropTypes from 'prop-types';
import SimpleContainer from 'components/shared/SimpleContainer';

class VideoElement extends React.Component {
  render() {
    const { title, title_color, url, autoplay, looping, description, top_description, bottom_description, icon } = this.props;

    const video = {
      marginTop: 20,
      marginBottom: 20
    };

    return (
      <SimpleContainer color={title_color} title={title} icon={icon}>
        { top_description ? 
          <div className="trix-content m-bottom-1" dangerouslySetInnerHTML={{ __html: top_description }} /> 
          :
          null }

        <div className="text-center">
          <video controls="controls" style={video} autoPlay={autoplay} muted={autoplay} loop={looping}>
            <source src={url} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>

        {/* { description ? 
          <div className="trix-content m-bottom-1" dangerouslySetInnerHTML={{ __html: description }} /> 
          :
          null } */}

        { bottom_description ? 
          <div className="trix-content m-bottom-1" dangerouslySetInnerHTML={{ __html: bottom_description }} /> 
          :
          null }

      </SimpleContainer>
    );
  }
}

VideoElement.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  url: PropTypes.string,
  autoplay: PropTypes.bool,
  looping: PropTypes.bool
};

export default VideoElement;
