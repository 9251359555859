import React from 'react';

const QuestionContainer = props => {
  return (
    <div className="f-col-s12 padless m-bottom-2 element-question">
      {props.children}
      <div className="clearfix"></div>
    </div>
  );
};

export default QuestionContainer;