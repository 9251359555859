import React from 'react';

const TeacherComment = props => {
  const comment = props.comment;
  const status = props.status;
  const commentStyling = {
    marginTop: 2,
    padding: "2px 4px"
  }

  return (
    <div className={status == 'rejected' ? 'danger radius' : 'secondary radius'} style={commentStyling}>
      <label className="text-bold">Comment:</label>
      {comment}
    </div >
  );
};

export default TeacherComment;
