import React, { useState } from "react";
import ElementContainer from "../../../shared/ElementContainer";
import Modal from "../../../shared/Modal";
import { FaTimes } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa"

const UnityGameElement = (props) => {
  const {
    title,
    title_color,
    id,
    requirable,
    status,
    token,
    completed,
    url,
    configuration,
    splash_image,
    active_track
  } = props;

  const [initialize, setInitialize] = useState(false);
  const [display, setDisplay] = useState(false);
  const closeDisplay = () => setDisplay(false);

  const cursor = {
    cursor: "pointer",
  };

  const closeButton = {
    borderBottomLeftRadius: 25,
    position: "fixed",
    top: 0,
    right: 0
   }

  return (
    <ElementContainer
      color={title_color}
      title={title}
      icon={"fas fa-laptop-code fa-lg"}
      requirable={requirable}
      status={status}
      completed={completed}
    >
      <a onClick={() => setInitialize(true)}>
        <div
          onClick={() => setDisplay(true)}
          style={cursor}
          className="btn btn-secondary expand radius drop-shadow"
        >
          <img src={splash_image} />
        </div>
      </a>

      {initialize ? (
        <Modal display={display} modalId={id} closeDisplay={closeDisplay}>
          <div className="btn btn-cs2n-red" style={closeButton}><FaTimes className="fa-2x"/> Close Activity</div>
          <iframe
            id="unity_game_element"
            src={`${url}?id=${id}&track_id=${active_track}&token=${token}&baseUrl=${window.location.origin}&configuration=${configuration}`}
          />
        </Modal>
      ) : null}
    </ElementContainer>
  );
};

export default UnityGameElement;
