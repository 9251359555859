import React from "react";
import AccordionContainer from "components/shared/AccordionContainer";

import GenericContent from "./GenericContent";
import CodeSnippetElement from "./CodeSnippetElement";
import AccordionContent from "./AccordionContent";
import EmbeddableContent from "./EmbeddableContent";
import VideoElement from "./VideoElement";

const components = {
  GenericContent: GenericContent,
  CodeSnippetElement: CodeSnippetElement,
  AccordionContent: AccordionContent,
  EmbeddableContent: EmbeddableContent,
  VideoElement: VideoElement,
};

const renderElement = (element) => {
  const ElementName = components[element.type];
  return (
    <ElementName
      title={element.title}
      title_color={element.title_color}
      body={element.body}
      content={element.content}
      language={element.language}
      key={element.id}
    />
  );
};

const EmbeddedPageElement = ({ elements, title, title_color }) => {
  return (
    <AccordionContainer color={title_color} title={title}>
      <div className="flex f-d-column">
        {elements.map(renderElement)}
      </div>
    </AccordionContainer>
  );
};

export default EmbeddedPageElement;
