import React from "react";

import { default as App } from "@carnegie-mellon-robotics-academy/rah_image_classification_prototype";
import ElementContainer from "../../../shared/ElementContainer";

const RahImageClassifier = (props) => {
  const {
    token,
    requirement,
    title,
    title_color,
    completed,
    domain,
    game_settings,
  } = props;
  console.log("domain", domain);
  return (
    <ElementContainer
      title={title}
      color={title_color}
      completed={completed}
      requirable={true}
      icon={"fas fa-gamepad fa-lg"}
    >
      <App game={{ token, requirement, domain, game_settings, completed }} />
    </ElementContainer>
  );
};

export default RahImageClassifier;
