import React from "react";
const Breadcrumbs = (props) => {
  return (
    <div className="store_breadcrumbs">
      <span
        className="home_crumb"
        onClick={() => {
          location.href = "https://www.cs2n.org";
        }}
      >
        CS2N
        <div className="arrow_container_red">
          <div className="right_arrow_red"></div>
        </div>
      </span>
      <div
        className="crumb_orange"
        onClick={() => {
          location.href = `cmra_store?category=${props.home_link}`;
        }}
      >
        Digital Store
        <div className="arrow_container_orange">
          <div className="right_arrow_orange"></div>
        </div>
      </div>
      <div
        className="crumb_yellow"
        onClick={() => {
          location.href = `cmra_store?category=${props.category.slug}`;
        }}
      >
        {props.category ? props.category.name : null}
        <div className="arrow_container_yellow">
          <div className="right_arrow_yellow"></div>
        </div>
      </div>
    </div>
  );
};
export default Breadcrumbs;
