import React, { useCallback, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import AddToCart from "./AddToCart";
const ProductView = (props) => {
  const policy = `<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Privacy</span></strong><span style='font-size:9.0pt;
	mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>We
	will only use personal information (such as name, mailing address or e-mail
	address) contained in your registration or correspondence to respond to your
	questions or provide Robotics Academy updates. If you enter a chat area or post
	messages, remember that other users may have access to your email address and
	user name. The Robotics Academy will not share any personal information
	provided by you with any unauthorized person or company except where the
	disclosure is required by law or is necessary to comply with legal process.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Certificate of Completion Requirements</span></strong><span
	style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>To
	earn the Certification, class attendees must complete all homework assignments
	to the satisfaction of the instructor and pass the certification exam.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Class Eligibility</span></strong><span style='font-size:
	9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>Classes
	at the Carnegie Mellon Robotics Academy are available to individuals who are at
	least 18 years of age. The Carnegie Mellon Robotics Academy reserves the right
	to restrict, suspend or terminate any student for violation of these policies.
	In consideration of your involvement with the Carnegie Mellon Robotics Academy,
	you agree to provide true, accurate and current information about yourself when
	you register. If you provide any information that is inaccurate or if the
	Carnegie Mellon Robotics Academy has reasonable grounds to suspect the
	information is inaccurate, the Carnegie Mellon Robotics Academy has the right
	to terminate your account.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Payment</span></strong><span style='font-size:9.0pt;
	mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>Purchasing
	a seat to online classes gives access to one participant only. Resources
	distributed as part of the class are for use of the participant only. Purchase
	Orders are also acceptable. Please contact the Carnegie Mellon Robotics Academy
	at&nbsp;</span><a href="mailto:cmra@nrec.ri.cmu.edu" target="_blank"><span
	style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>cmra@nrec.ri.cmu.edu</span></a><span
	style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>&nbsp;for
	information about registering for the course, payment for the course, or if the
	course will be held.&nbsp; Please email&nbsp;if you have questions about the
	content of the course.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Internet Access / Bandwidth Fees</span></strong><span
	style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>The
	Carnegie Mellon Robotics Academy is not responsible for any internet access or
	bandwidth fees you incur while taking this class.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>International Export Controls</span></strong><span
	style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>International
	enrollment is subject to applicable United States export controls and laws.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Refunds</span></strong><span style='font-size:9.0pt;
	mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>The
	Carnegie Mellon Robotics Academy will offer partial refunds for tuition
	expenses only if class registrants contact us prior to the beginning of the
	first class.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Copyrighted Class Material</span></strong><span
	style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>All
	course documents are owned by the Carnegie Mellon Robotics Academy. These
	materials may not be reprinted in any form except those specified for
	instructional purposes. The course documents and presentations may be displayed
	and printed for personal, non-commercial use only. Only students registered for
	this course may access this material. The Carnegie Mellon Robotics Academy
	makes every effort to provide accurate and up-to-date content. However, we have
	no liability for the accuracy, content, or accessibility of the hyperlinks
	included with class material.<o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>The
	Carnegie Mellon Robotics Academy will host materials including live session
	recordings and presentations for up to one year from the completion of the
	course. Participants are expected to download these materials for their
	long-term reference.&nbsp;<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Use of Student Material</span></strong><span
	style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>The
	Carnegie Mellon Robotics Academy reserves the right to use coursework done by
	students for the purpose of advancing the educational mission of the Academy.
	When this occurs, students will be given the option to have their name credited
	to the material. This includes, but is not limited to, text, graphics,
	multimedia and other material created as part of the Carnegie Mellon Robotics
	Academy online course assignments.<o:p></o:p></span></p>
	
	<p><strong><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:
	"Arial",sans-serif'>Indemnification</span></strong><span style='font-size:9.0pt;
	mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'><o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>You
	agree to indemnify and hold the Carnegie Mellon Robotics Academy harmless from
	any and all losses, actions, controversies, suits, demands, claims, liabilities
	or any causes of action whatsoever. You expressly agree that the Carnegie
	Mellon Robotics Academy is not responsible or liable for any infringement of
	another’s rights, including intellectual property rights.<o:p></o:p></span></p>
	
	<p><span style='font-size:9.0pt;mso-bidi-font-size:12.0pt;font-family:"Arial",sans-serif'>Note:
	Policies are subjected to occasional revisions.<o:p></o:p></span></p>
	
	<p class=MsoNormal><span style='font-size:8.0pt;mso-bidi-font-size:11.0pt;
	line-height:107%;font-family:"Arial",sans-serif'><o:p>&nbsp;</o:p></span></p>
	`;

  return (
    <div key={"content"} className="product-content-wrapper">
      <div className="product-page-title">{props.selectedProduct.name}</div>
      <div className="product-page">
        <ImageGallery items={props.selectedProduct.images} />
        <div className="product-page-body">
          <div className="product-page-price">
            ${props.selectedProduct.price}
          </div>
          <AddToCart
            handleClick={(e, quantity) => {
              props.handleCart(e, props.selectedProduct, quantity);
            }}
          />
          <div
            className="product-page-description"
            dangerouslySetInnerHTML={{
              __html: props.selectedProduct.description + policy,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductView;
