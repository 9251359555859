import React, { useEffect } from "react";

const StoreNavigation = (props) => {
  const cs2n_url =
    process.env.NODE_ENV == "development"
      ? "http://localhost:5000/"
      : "https://www.cs2n.org/";
  const handleCategoryClickCallback = (category) => {
    const url = new URL(location);
    history.pushState({}, "", url);
    window.location = `${cs2n_url}cmra_store?category=${category.slug}`;
  };

  const emptyProducts = [
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
  ];
  return props.categories
    ? props.categories.map((category, index) => (
        <div
          onClick={() => {
            handleCategoryClickCallback(category);
          }}
          className={
            props.selectedCategory &&
            props.selectedCategory.name == category.name
              ? "category-button-select"
              : "category-button"
          }
          key={"taxon" + index}
        >
          <i className="fa fa-bars"></i>
          <span className="button-title">{category.name}</span>
        </div>
      ))
    : emptyProducts.map((category, index) => (
        <div className={"category-button"} key={"taxon" + index}>
          <i className="fa fa-bars"></i>
          <span className="button-title">{category.name}</span>
        </div>
      ));
};

export { StoreNavigation };
