import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import Time from "react-time-format";
import ElementContainer from "../../../shared/ElementContainer";
import Modal from "components/shared/Modal";

class QuizElement extends React.Component {
  render() {
    const {
      quiz,
      quizAttempts,
      titleColor,
      completed,
      requirable,
      page,
      pageType,
      courseId,
      id,
      min_score,
      hide_score,
      user,
    } = this.props;

    return (
      <ElementContainer
        color={titleColor}
        title={quiz.name}
        requirable={requirable}
        completed={completed}
        icon={"fa-file-signature"}
      >
        {quiz.time_limit > 0 ? (
          <div className="m-bottom-1 text-center">
            Complete activity with a minimum passing score: {min_score}% within{" "}
            {quiz.time_limit} minutes
          </div>
        ) : (
          <div className="m-bottom-1 text-center">
            Complete activity with a minimum passing score: {min_score}%
          </div>
        )}

        <StartActivity
          pageType={pageType}
          page={page}
          id={id}
          quiz={quiz}
          user={user}
          courseId={courseId}
        />
        <Attempts
          attempts={quizAttempts}
          minimum={min_score}
          hide_score={hide_score}
        />
      </ElementContainer>
    );
  }
}

const StartActivity = (props) => {
  const { pageType, page, courseId, id, quiz, user } = props;
  const [display, setDisplay] = useState(false);
  const ModalHeader = (props) => {
    const { title } = props;
    const span = {
      position: "absolute",
      top: 15,
      right: 15,
    };
    const btn = {
      cursor: "pointer",
      color: "lightgray",
    };
    return (
      <div className="modal-header">
        <h2 className="text-center">{title}</h2>
        <span style={span}>
          <a onClick={() => setDisplay(false)} style={btn}>
            <FaTimes className="fa-2x" />
          </a>
        </span>
      </div>
    );
  };

  return (
    <form
      action={
        courseId != undefined
          ? `/u/mp/${pageType}/${courseId}/${page}/quiz_attempts/`
          : `/u/mp/${pageType}/${page}/quiz_attempts`
      }
      method="post"
    >
      <input type="hidden" value={quiz.id} name="[quiz_attempt][quiz_id]" />
      <input type="hidden" value={id} name="[quiz_attempt][quiz_element_id]" />
      <input type="hidden" value={user.id} name="[quiz_attempt][user_id]" />

      {quiz.time_limit > 0 ? (
        <div
          className="btn btn-secondary radius expand drop-shadow"
          onClick={() => setDisplay(true)}
        >
          Start Activity
        </div>
      ) : (
        <input
          type="submit"
          className="btn btn-secondary radius expand drop-shadow"
          value="Start Activity"
        />
      )}

      <Modal display={display} modalId={quiz.id}>
        <ModalHeader title={"Notice!"} />
        <div className="modal-body">
          <p className="text-center">
            You are about to start a time-based activity
            <br></br>
            that allows for{" "}
            <span className="primary-text">{quiz.time_limit} minutes</span>.
            <br></br>
            Are you sure you want to start it?
          </p>

          <input
            type="submit"
            className="btn radius expand"
            value="Acknowledge and Start Activity"
          />
        </div>
      </Modal>
    </form>
  );
};

const Attempts = (props) => {
  const { attempts, minimum, hide_score } = props;
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th className="text-center">Started</th>
            <th className="text-center">Completed</th>
            {!hide_score && (
              <>
                <th className="text-center">Questions (Correct/Total)</th>
                <th className="text-origin-center">Score</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {attempts.map((a) => (
            <tr key={a.id}>
              <td className="text-center">
                <Time value={a.created_at} format="MM/DD/YYYY" />
              </td>
              <td className="text-center">{a.closed ? <FaCheck /> : "~~~"}</td>
              {!hide_score && (
                <>
                  <td className="text-center">
                    {a.point_total} / {a.points_available}
                  </td>
                  <td
                    className={
                      a.score > minimum
                        ? "primary radius text-center"
                        : "secondary radius text-center"
                    }
                  >
                    {a.score}%
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuizElement;
