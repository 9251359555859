import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { CustomerCheckout } from "./CustomerCheckout";
import { jsPDF } from "jspdf";
import { StoreNavigation } from "./store_components/StoreNavigation";

const CmraCart = (props) => {
  const cs2n_url =
    process.env.NODE_ENV == "development"
      ? "http://localhost:5000/"
      : "https://www.cs2n.org/";

  const cs2n_store_categories = "client_api/v1/get_store_categories/";
  const cs2n_store_products = "client_api/v1/get_store_product/";
  const [cart, setCart] = useState(null);
  const [products, setProducts] = useState(null);
  const [total, setTotal] = useState(0);
  const [checkout, setCheckout] = useState(false);
  const [categories, setCategories] = useState(null);
  const [storeQuantity, setStoreQuantity] = useState(0);

  const pdfRef = useRef(null);

  useEffect(() => {
    get_categories();
    if (localStorage.getItem("cmra_store")) {
      setCart(JSON.parse(localStorage.getItem("cmra_store")));
    }
  }, [null]);

  useEffect(() => {
    if (cart) {
      var total = 0;
      for (let index = 0; index < cart.length; index++) {
        const item = cart[index];
        total = total + parseInt(item.quantity);
      }
      setStoreQuantity(total);
      if (!products) {
        const productLine = new Array();
        for (let i = 0; i < cart.length; i++) {
          productLine.push(cart[i].product);
        }
        setProducts(productLine);
      }
      var temp_total = 0;
      for (let i = 0; i < cart.length; i++) {
        const product = cart[i].product;
        const cartItem = cart[i];
        temp_total += parseInt(product.price) * parseInt(cartItem.quantity);
      }
      setTotal(temp_total);
    }
  }, [cart]);

  const get_categories = async (slug = null) => {
    const options = {
      method: "GET",
      url: `${cs2n_url + cs2n_store_categories}`,
      params: { slug },
      headers: { Accept: "application/vnd.api+json" },
    };

    try {
      const { data } = await axios.request(options);

      // Filter out the "hidden" category
      const filteredData = data.filter(
        (category) => category.slug !== "hidden"
      );
      filteredData.sort(
        (a, b) => parseInt(a.sort_order) - parseInt(b.sort_order)
      );
      setCategories(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  const remove_all_product = (slug) => {
    var index = 0;
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (element.slug == slug) {
        index = i;
        break;
      }
    }
    var new_cart = cart.slice();
    new_cart.splice(index, 1);
    localStorage.setItem("cmra_store", JSON.stringify(new_cart));
    setTotal(0);
    setCart(new_cart);
  };

  const handleExportToPdf = () => {
    var pdfsize = "letter";
    var doc = new jsPDF("p", "pt", pdfsize);
    doc.html(pdfRef.current, {
      async callback(doc) {
        await doc.save("pdf_name");
      },
    });
  };
  const handleCheckout = () => {
    setCheckout(true);
  };
  const handleSubmit = () => {
    setCheckout(false);
  };
  const handleCart = (product, quantity) => {
    var store = localStorage.getItem("cmra_store");
    if (store) {
      store = JSON.parse(store);
      var hasItem = false;

      for (let index = 0; index < store.length; index++) {
        const item = store[index];

        if (item.slug == product.slug) {
          item.quantity = String(quantity);
          // save store
          localStorage.setItem("cmra_store", JSON.stringify(store));
          setCart(store);
          hasItem = true;
          break;
        }
      }
      if (!hasItem) {
        store.push({
          slug: product.slug,
          quantity: String(quantity),
        });
        localStorage.setItem("cmra_store", JSON.stringify(store));
        setCart(store);
      }
    } else {
      var store = [{ slug: product.data.attributes.slug, quantity: quantity }];
      localStorage.setItem("cmra_store", JSON.stringify(store));
      setCart(store);
    }
  };

  const handleQualityUpdate = (props, data) => {
    handleCart(props.product, data.value);
  };

  return (
    <div className="store_body" key={"store_body"}>
      <img
        key={"header_banner"}
        style={{
          paddingLeft: "50px",
          minHeight: "112px",
          minWidth: "996px",
          maxWidth: "996px",
          maxHeight: "112px",
        }}
        src="https://cs2n.s3.amazonaws.com/assets/static/store/CMRA+Store+Banner_01_store.png"
      />
      <div className="store-front-wrapper">
        <div className="taxon_navigation">
          <span className="navigation-header-text">Products</span>
          <StoreNavigation categories={categories} selectedCategory={null} />
        </div>

        {!checkout ? (
          cart && cart.length > 0 ? (
            <div className="cart_table">
              <span className="my-cart-text">
                My Cart
                <span className="my-cart-item-text">
                  {" (" + storeQuantity + " items)"}
                </span>
              </span>
              {cart.map((item) => (
                <ProductRow
                  key={item.slug + "_cart_item"}
                  slug={item.slug}
                  onQuantityChange={handleQualityUpdate}
                  product={item.product}
                  quantity={item.quantity}
                  remove_all={remove_all_product}
                />
              ))}
              {/* <div className="hidden_pdf">
                  <div ref={pdfRef} className="pdf_content"></div>
                </div> */}
            </div>
          ) : (
            <div className="cart_product">
              <span className="empty_text">Empty</span>
            </div>
          )
        ) : (
          <div className="checkout">
            <CustomerCheckout
              username={props.username}
              email={props.email}
              cart={cart}
              submit={handleSubmit}
            />
          </div>
        )}
        <div className="total-bg">
          <span className="total-summary-label">
            <img
              src={
                "https://cs2n.s3.amazonaws.com/assets/static/store/Cart_empty.png"
              }
            />
            Order Summary
          </span>
          <span className="total-property">
            <span>Subtotal:</span>
            <span className="bold-property">${total.toFixed(2)}</span>
          </span>
          <span className="total-property">
            <span>Est Tax:</span>
            <span className="bold-property">${"0.00"}</span>
          </span>
          <span className="total-property">
            <span>Est Shipping & Handling:</span>
            <span className="bold-property">${"0.00"}</span>
          </span>
          <span className="total-property">
            <span>Total:</span>
            <span className="bold-property-total">${total.toFixed(2)}</span>
          </span>
          {products && products.length > 0 ? (
            !checkout && (
              <div className="checkout-button" onClick={handleCheckout}>
                <span>Start Secure Checkout:</span>
              </div>
            )
          ) : (
            <div
              className="checkout-button"
              onClick={() => {
                window.location = `${cs2n_url}cmra_store`;
              }}
            >
              <span>Go Shopping:</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ProductRow = (props) => {
  const options = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
  ];
  const [quantity, setQuantity] = useState(props.quantity);
  const handleSelected = (data) => {
    props.onQuantityChange(props, data);
    setQuantity(data.value);
  };
  const remove_all = () => {
    props.remove_all(props.product.slug);
  };

  return (
    <div key={"cart_product" + props.product.id} className="cart_product">
      <img
        className="cart-product-image"
        src={props.product.images[0].thumbnail}
      />
      <div className="cart-product-title">{props.product.name}</div>
      <div className="cart-product-quantity">
        <span>QTY:</span>
        <Dropdown
          onClick={(e) => {}}
          options={options}
          onChange={handleSelected}
          value={quantity}
          placeholder={options[quantity]}
          controlClassName="product-card-dropdown"
        />
      </div>
      <div className="cart-product-price">
        ${props.product.price * props.quantity}
      </div>

      <div className="remove-item" onClick={remove_all}>
        X
      </div>
    </div>
  );
};
export default CmraCart;
