const HOST = "https://d36ndnmww3x0xq.cloudfront.net/";

export async function uploadFileAttachment(attachment) {
  if (typeof attachment === "undefined" || attachment == null) {
    throw "No file present. Please upload a file!";
  }

  const key = createStorageKey(attachment);
  return await uploadFile(attachment, key);
}

function uploadFile(file, key) {
  return new Promise((resolve) => {
    var formData = createFormData(key, file);
    var xhr = new XMLHttpRequest();

    xhr.open("POST", HOST, true);

    xhr.upload.addEventListener("progress", function (event) {
      var progress = (event.loaded / event.total) * 100;
    });

    xhr.addEventListener("load", function (event) {
      if (xhr.status == 204) {
        var attributes = {
          url: HOST + key,
        };
        resolve(attributes);
      }
    });

    xhr.send(formData);
  });
}

function createStorageKey(file) {
  var date = new Date();
  var name = date.getTime() + "-" + file.name.replace(/\s/gi, "");
  var resource = "uploads/mixed_media";
  return [resource, name].join("/");
}

function createFormData(key, file) {
  var data = new FormData();
  data.append("key", key);
  data.append("file", file);
  data.append("Content-Type", file.type);
  return data;
}
