import React from "react";
import SimpleContainer from "components/shared/SimpleContainer";

class GenericContent extends React.Component {
  render() {
    const { title, title_color, body, icon } = this.props;
    return (
      <SimpleContainer color={title_color} title={title} icon={icon}>
        <div className="trix-content" dangerouslySetInnerHTML={{ __html: body }} />
      </SimpleContainer>
    );
  }
}

export default GenericContent;
