import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "react-dropdown";

const AddToCart = (props) => {
  const options = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
  ];
  const [quantity, setQuantity] = useState("1");
  const handleSelected = (data) => {
    setQuantity(data.value);
  };

  return (
    <div className="product-card-cart">
      <div
        onClick={(e) => {
          if (quantity == 0) {
            props.handleClick(e, "1");
          } else {
            props.handleClick(e, quantity);
          }
        }}
      >
        Add to cart
      </div>

      <Dropdown
        onClick={(e) => {}}
        options={options}
        onChange={handleSelected}
        value={quantity}
        placeholder={options[0]}
        controlClassName="product-card-dropdown"
      />
      <div
        className="add_to_cart_image"
        onClick={(e) => {
          if (quantity == 0) {
            props.handleClick(e, 1);
          } else {
            props.handleClick(e, quantity);
          }
        }}
      >
        <img
          src={
            "https://cs2n.s3.amazonaws.com/assets/static/store/Cart_empty.png"
          }
        />
      </div>
    </div>
  );
};
export default AddToCart;
