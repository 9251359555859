import React, { Component } from "react";

class Modal extends Component {
  render() {
    const showModal = {
      display: "flex",
    };

    const hideModal = {
      display: "none"
    }

    const { modalId, display, children, closeDisplay } = this.props;

    return(
      <div
        id={modalId}
        className="react-modal"
        style={ display ? showModal : hideModal }
        onClick={closeDisplay}
      >
        <div className="react-modal-content">{children}</div>
      </div>
    )
  }
}

export default Modal;
