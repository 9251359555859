import React, { useState } from 'react';
import QuestionContainer from '../../../shared/QuestionContainer';
import axios from 'axios';

const QuizQuestionElement = (props) => {
  const {
    question,
    answers,
    token,
    titleColor,
    title,
    id,
    quizResponses,
    attempt,
  } = props;
  const [response, setResponse] = useState(currentResponse(id, quizResponses));
  return (
    <QuestionContainer color={titleColor} title={title}>
      <Question html={question} />
      <div className="f-col-s12">
        <form>
          {answers.map((answer) => {
            return (
              <Answer
                question_id={id}
                id={answer.id}
                key={answer.id}
                html={answer.answer}
                token={token}
                response={response}
                setResponse={setResponse}
                attempt={attempt}
                displayName="answer"
              />
            );
          })}
        </form>
      </div>
    </QuestionContainer>
  );
};

const currentResponse = (question_id, responses = []) => {
  const response = responses.find((r) => r.question_element_id === question_id);
  if (response) {
    return response;
  }
  return { question_element_answer_id: null };
};

const Question = (props) => {
  const { html } = props;
  const borderLine = {
    borderBottom: "1px solid black",
    paddingBottom: "1em"
  }

  return (
    <div className="f-col-s12 p-left-0 p-right-0 p-top-0 flex f-d-row m-bottom-1" style={borderLine}>
      <span className="m-right-1">
        <i className="fas fa-question-circle fa-lg" />
      </span>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
};

const Answer = (props) => {
  const {
    html,
    id,
    question_id,
    token,
    response,
    setResponse,
    attempt,
  } = props;
  const qId = 'question' + id;

  const submitAnswer = (e) => {
    e.preventDefault();

    const data = {
      quiz_response: {
        question_element_id: question_id,
        question_element_answer_id: e.target.value,
      },
    };

    const endpoint = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.id) {
      endpoint
        .put(
          `/client_api/v1/quiz_attempts/${attempt.id}/quiz_responses/${response.id}`,
          data
        )
        .then((r) => setResponse(r.data))
        .catch((e) => console.log('error', e));
    } else {
      endpoint
        .post(`/client_api/v1/quiz_attempts/${attempt.id}/quiz_responses`, data)
        .then((r) => setResponse(r.data))
        .catch((e) => console.log('error', e));
    }
  };

  const cursorPointer = {
    cursor: "pointer"
  }

  return (
    <div className="f-col-s12 padless">
      <input
        id={qId}
        type="radio"
        name="answer"
        value={id}
        className="btn-form checked-secondary radius"
        checked={response.question_element_answer_id === id}
        onClick={(e) => {
          submitAnswer(e);
        }}
      />
      <label
        htmlFor={qId}
        className="expand drop-shadow"
        style={cursorPointer}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
};

export default QuizQuestionElement;
