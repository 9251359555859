import React from 'react';
import PropTypes from 'prop-types';
import { CompactPicker } from 'react-color';

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { color: props.color || '' };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ color: e.hex });
  }

  render() {
    const { color } = this.state;
    const { resource, field } = this.props;
    return (
      <div>
        <input type="hidden" name={`${resource}[${field}]`} value={color} />
        <CompactPicker
          triangle="hide"
          onChange={this.handleChange}
          color={color}
        />
      </div>
    );
  }
}

ColorPicker.propTypes = {
  resource: PropTypes.string,
  field: PropTypes.string
};

export default ColorPicker;
