import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QuestionContainer from '../../../shared/QuestionContainer';
import axios from 'axios';

const CyuQuestionElement = (props) => {
  const [result, setResult] = useState(null);
  const { question, answers, token, titleColor, title } = props;
  const endpoint = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

  const checkAnswer = (answer) => {
    setResult(null);
    endpoint
      .get(`/client_api/v1/cyu_evaluations/${answer}`)
      .then((r) => {
        setResult(r.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (

      <QuestionContainer color={titleColor} title={title}>
        <Question html={question} />
        <div className="f-col-s12 padless">
          <form>
            {answers.map((answer) => {
              return (
                <Answer
                  checkAnswer={checkAnswer}
                  id={answer.id}
                  key={answer.id}
                  html={answer.answer}
                  result={result}
                />
              );
            })}
          </form>
        </div>
      </QuestionContainer>

  );
};

const Question = (props) => {
  const { html } = props;
  const borderLine = {
    borderBottom: "1px solid black",
    paddingBottom: "1em"
  }

  return (
    <div className="f-col-s12 p-left-0 p-right-0 p-top-0 flex f-d-row m-bottom-1" style={borderLine}>

      <span className="m-right-1">
        <i className="fas fa-question fa-lg" />
      </span>

      <div className="full-width"
        dangerouslySetInnerHTML={{ __html: html }}
      />

    </div>
  );
};

const Answer = (props) => {
  const { html, id, checkAnswer } = props;
  const qId = 'question' + id;
  const { result } = props;
  const answerStyling = {
    cursor: "pointer",
    fontWeight: "unset"
  }

  return (
    <div className="f-col-s12 padless">
      <input
        id={qId}
        type="radio"
        name="answer"
        className={ result ? (
          (result['correct?']) ? "btn-form checked-success radius" : "btn-form checked-danger radius")
          : "btn-form checked-secondary radius" }
        value={id}
        onClick={(e) => checkAnswer(e.target.value)}
      />
      
      <label
        className="expand drop-shadow"
        style={answerStyling}
        htmlFor={qId}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
};

CyuQuestionElement.propTypes = {
  question: PropTypes.string,
  answers: PropTypes.array,
  token: PropTypes.string,
};
export default CyuQuestionElement;
