import React from "react";
import { FaCheck } from "react-icons/fa";

const ElementContainer = (props) => {
  {
    /* console.log('ElementContainer', props); */
  }
  const {
    completed,
    requirable,
    reviewable,
    color,
    title,
    status,
    icon,
  } = props;
  
  const borderLine = {
    borderBottom: "1px solid black",
    borderColor: color,
    paddingBottom: "1em"
  }

  return (
    <div className="f-col-s12 padless m-bottom-2 element-complex">
      <div className="f-col-s12 p-left-0 p-right-0 p-top-0 flex f-d-row m-bottom-1" style={borderLine}>
        <span className="m-right-1 flex f-a-self-center">
          { icon && !requirable ? (
            <i className={`fas ${icon} fa-lg`} />
          ) : null }
          { requirable ? (
            <RequirementStatus completed={completed} icon={icon} />
          ) : null }
          
        </span>

        <div className="flex f-d-column expand">
          <h2 className="marginless">
            {title}
          </h2>
          { reviewable ? <ReviewableStatus status={status} /> : null }
        </div>
      </div>

      {props.children}

      <div className="clearfix"/>
    </div>
  );
};

const RequirementStatus = (props) => {
  const completed = props.completed;
  const icon = props.icon;
  const iconStatus = {
    backgroundColor:(completed ? "limegreen" : "none" ),
    display: "flex",
    padding: 7,
    borderRadius: 5
  }

  const checkColor = {
    color: "white",
    paddingLeft: 5,
  }

  return (
    <span style={iconStatus} >
      <i
        className={completed ? `fas ${icon}` : `fas ${icon} fa-lg`}
        style={completed ? { color: "white" } : { color: "gray" }}
      />
      { completed ? <FaCheck style={checkColor}/> : null }
      
    </span>

  );
};

const ReviewableStatus = (props) => {
  const { status } = props;
  const fontSize = {
    fontSize: ".9em", 
    padding: "2px 4px"
  }

  switch (status) {
    case "incomplete":
      return (
        <div className="f-col-s12 padless">
          <div className="f-col-s12 secondary font-size-half radius" style={fontSize}>
            <i className="fas fa-exclamation-circle primary-text m-right-half"/>
            This upload must be verified. Upload to continue..
          </div>
        </div>
      );

    case "submitted":
      return (
        <div className="f-col-s12 padless">
          <div className="f-col-s4 radius p-right-0 primary font-size-half" style={fontSize}>
            <i className="fas fa-check-circle secondary-text m-right-half"/>
            Uploaded
          </div>
          <div className="f-col-s8 radius-right p-left-0 secondary font-size-half" style={fontSize}>
            <i className="fas fa-exclamation-circle primary-text m-right-half"/>
            Awaiting verifications..
          </div>
        </div>
      );

    case "rejected":
      return (
        <div className="f-col-s12 padless">
          <div className="f-col-s4 radius-left p-right-0 primary font-size-half" style={fontSize}>
            <i className="fas fa-check-circle secondary-text m-right-half"/>
            Uploaded
          </div>
          <div className="f-col-s4 radius-right p-right-0 p-left-0 primary font-size-half" style={fontSize}>
            <i className="fas fa-check-circle secondary-text m-right-half"/>
            Verified
          </div>
          <div className="f-col-s4 radius-right p-left-0 secondary font-size-half" style={fontSize}>
            <i className="fas fa-times-circle danger-text m-right-half"/>
            Rejected..
          </div>
        </div>
      );

    case "completed":
      return (
        <div className="f-col-s12 padless">
          <div className="f-col-s4 radius-left p-right-0 primary font-size-half" style={fontSize}>
            <i className="fas fa-check-circle secondary-text m-right-half"/>
            Uploaded
          </div>
          <div className="f-col-s4 p-right-0 p-left-0 primary font-size-half" style={fontSize}>
            <i className="fas fa-check-circle secondary-text m-right-half"/>
            Verified
          </div>
          <div className="f-col-s4 radius-right p-left-0 primary font-size-half" style={fontSize}>
            <i className="fas fa-check-circle secondary-text m-right-half"/>
            Accepted
          </div>
        </div>
      );
  }

  return <div></div>;
};

export default ElementContainer;
