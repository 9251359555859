import React, { useCallback, useEffect, useState } from "react";
import AddToCart from "./AddToCart";
const CategoryView = (props) => {
  const handleCart = useCallback((e, p, q) => {
    // pass cart button to store...
    props.handleCart(e, p, q);
  });
  return (
    <div className="category-view">
      <div key="content" className="content-title">
        <div className="content-title-label fas">
          {props.selectedCategory.name}
        </div>
        <div className="content-title-info">
          {props.selectedCategory.image && (
            <img
              className="product_thumbnail"
              src={props.selectedCategory.image}
            />
          )}
          <div
            className="content-title-description"
            dangerouslySetInnerHTML={{
              __html: props.selectedCategory.description,
            }}
          />
        </div>
      </div>
      <div className="product-card-content-wrapper">
        {props.selectedCategory.products.map((product, key) => (
          <ProductCard
            product={product}
            key={"product" + key}
            handleCart={handleCart}
          />
        ))}
      </div>
    </div>
  );
};

const ProductCard = (props) => {
  const cs2n_url =
    process.env.NODE_ENV == "development"
      ? "http://localhost:5000/"
      : "https://www.cs2n.org/";

  const handleReadMore = () => {
    const url = new URL(location);
    history.pushState({}, "", url);
    window.location = `${cs2n_url}cmra_store?product=${props.product.slug}`;
  };
  return (
    <div className="product-card-option">
      <div className="product-card-title">{props.product.name}</div>
      <img
        className="product_card_thumbnail"
        src={props.product.images[0].thumbnail}
      />

      <div
        className="product-card-description"
        dangerouslySetInnerHTML={{
          __html: props.product.description.body,
        }}
      />
      <div className="product-card-read" onClick={handleReadMore}>
        More Info
      </div>
      <div className="product-card-price">${props.product.price}</div>
      <div className="product-card-button-row">
        <AddToCart
          handleClick={(e, quantity) => {
            props.handleCart(e, props.product, quantity);
          }}
        />
      </div>
    </div>
  );
};
export default CategoryView;
